// eslint-disable-next-line no-redeclare,no-unused-vars
function calculate(value, settings, row, param) {
  if (!isString(value)) return value;
  else if (strpos(value, '@') !== false) return value;
  else if (strpos(value, 'auto') === 0) return null;
  if (!settings) settings = {};
  if (!param) param = {};

  var calc = false;
  var command = false;
  if (str_contains(value, ':') && !str_starts_with(value, 'http')) {
    [command, value] = value.split(':');
    calc = true;
  }

  if (str_starts_with(value, "'") && str_ends_with(value, "'"))
    value = value.slice(1, -1);
  else if (str_contains(value, '__'))
    value = convertPattern({text: value, row: row, object: param.object});
  else if (
    str_contains(value, '.') &&
    !str_contains(value, '|') &&
    !str_contains(value, ',') &&
    !str_starts_with(value, 'http')
  ) {
    var key, refKey, refKey2, refObj;
    [key, refKey, refKey2] = value.split('.');
    var refSettings = getObjectValue(fields, [param.object, key]);
    if (key == 'user') {
      refObj = 'user';
      value = user[refKey];
    } else if (key == 'store' && (!row || !isset(row.store))) {
      refObj = 'store';
      value = store[refKey];
    } else if (param.object && row && refSettings) {
      refObj = refSettings.reference;
      if (refObj && row[key])
        value = getData(refObj, row[key], refKey, refSettings.objectSub);
    }
    if (refKey2 && refObj && value) {
      refSettings = getObjectValue(fields, [refObj, refKey]);
      if (refSettings.reference)
        value = getData(
          refSettings.reference,
          value,
          refKey2,
          refSettings.objectSub
        );
    }
    if (param.read && isArray(value) && !settings.array) {
      value = value[0];
      if (isPlainObject(value) && value.value) value = value.value;
    }
    if (!isString(value)) return value;
  }

  // calculations
  if (inArray(command, ['date', 'datetime', 'week'])) {
    var format = 'YYYY-MM-DD';
    if (command == 'datetime') format += ' HH:mm';
    else if (command == 'week') format = 'YYYY-\\WWWW';
    if (row && isString(row[value])) value = row[value];
    if (strpos(value, 'today') !== false)
      value = value.replace('today', moment().format('YYYY-MM-DD'));
    if (strpos(value, 'year') !== false)
      value = value.replace('year', moment().format('YYYY'));
    if (value == 'y') value = moment().format('YY');
    else if (value == 'Y') value = moment().format('YYYY');
    else if (value == 'Y-m-01') value = moment().format('YYYY-MM-01');
    // else if (value.slice(0, 1) == '+')
    // 	value = moment()
    // 		.add(value.slice(1, strpos(value, ' ') - 1), value.slice(strpos(value, ' ') + 1))
    // 		.format(format);
    // else if (value.slice(0, 1) == '-')
    // 	value = moment()
    // 		.subtract(value.slice(1, strpos(value, ' ') - 1), value.slice(strpos(value, ' ') + 1))
    // 		.format(format);
    else {
      var momentObj = moment(value);
      if (!momentObj.isValid() && value == 'first day of last month')
        momentObj = moment().subtract(1, 'months').startOf('month');
      if (!momentObj.isValid()) {
        value = strtotime(value);
        if (value) momentObj = moment(value * 1000);
      }
      if (momentObj.isValid()) value = momentObj.format(format);
      else value = '';
      if (command == 'week') value = value.replace('WW', 'W');
    }
  } else if (command == 'year')
    value = moment(strtotime(value) * 1000).format('YYYY');
  else if (command == 'last' && isArray(row[value])) value = end(row[value]);
  else if (command == 'oposite') {
    value = row[value];
    value = value ? 0 : 1;
  } else if (command == 'cond') {
    var val, val1, val2;
    [key, val, val1, val2] = value.split(',');
    value = row[key];
    if (!isset(value)) value = '';
    value = value == val ? val1 : val2;
    if (value && row[value]) value = row[value];
    else if (!value) value = null;
  } else if (command == 'copy' && value && param.element) {
    var rowAdd = {};
    if (settings.adopt)
      $.each(settings.adopt, function (key, val) {
        rowAdd[key] = calculate(
          val,
          getObjectValue(fields, [settings.reference, key]),
          row,
          {object: param.object}
        );
      });
    if (isArray(value)) {
      var ids = [];
      $.each(value, function (i, id) {
        copy(id, {
          object: settings.reference,
          objectSub: settings.objectSub,
          rowAdd: rowAdd,
          nameOrig: true,
          callback: function (xhr) {
            ids.push(xhr.id);
            param.element.val(ids);
            if (ids.length == value.length) param.element.save();
          },
        });
      });
    } else
      copy(value, {
        object: settings.reference,
        objectSub: settings.objectSub,
        rowAdd: rowAdd,
        nameOrig: true,
        callback: function (xhr) {
          param.element.val(xhr.id).save();
        },
      });
    return false;
  } else if (
    row &&
    param.object &&
    fields[param.object][value] &&
    param.key != value
  ) {
    if (fields[param.object][value].labels)
      value =
        fields[param.object][value].labels[
          fields[param.object][value].values.indexOf(row[value])
        ];
    else value = row[value];
  } else if (inArray(value, ['industry', 'multiStore'])) value = window[value];
  else if (value == 'now' && !param.convertWhere)
    value = moment().format('YYYY-MM-DD HH:mm:ss');
  else if (value == 'today') value = moment().format('YYYY-MM-DD');
  else if (value == 'time' && !param.convertWhere)
    value = moment().format('HH:mm');
  else if (inArray(value, ['month', 'month2']))
    value = moment().format('YYYY-MM');
  else if (value == 'year') value = moment().format('YYYY');
  else if (value == 'user') value = user.id;
  else if (value == 'role' || value == 'roll') value = role;
  else if (value == 'store') value = store.id;
  else if (value == 'url') value = document.location.origin;
  else if (value == 'client' && calc) value = client;
  else if (value == 'notificationPermission') value = Notification.permission;
  else if (value == 'object') value = param.refObj;
  else if (value == 'objectSub') value = param.refObjSub;
  else if (value == 'jsData') {
    value = {tables: {}};
    $.each(['userAgent', 'language'], function (i, key) {
      value[key] = navigator[key];
    });
    $.each(
      [
        'title',
        'industry',
        'jsVersion',
        'dim',
        'domain',
        'endpoint',
        'outerWidth',
        'outerHeight',
        'origin',
      ],
      function (i, key) {
        value[key] = window[key];
      }
    );
    if (window.performance)
      $.each(
        ['jsHeapSizeLimit', 'totalJSHeapSize', 'usedJSHeapSize'],
        function (i, key) {
          value[key] = performance.memory[key];
        }
      );
    value = JSON.stringify(value);
  } else if (value == 'screenshot') {
    value = function (line) {
      html2canvas(document.body, {
        ignoreElements: function (element) {
          var ident = $(element).attr('ident');
          if (ident && str_starts_with(ident, 'bug,')) return true;
          else if ($(element).hasClass('ui-widget-overlay')) return true;
        },
      }).then((canvas) => {
        var img = canvas.toDataURL('image/png');
        var file =
          'screenshot' + moment().format('YYYY-MM-DD_HH:mm:ss') + '.png';
        ajax({
          object: 'document',
          id: 'new',
          post: true,
          send: {content: img, file: file},
          callback: function (xhr) {
            line.add(xhr);
          },
        });
      });
    };
  }
  // arithmatic operation
  // else if (value && value.match(/^[0-9][^+/\*\\-].*[+/\*\\-]/)) { -> no row vars in beginning included
  else if (value && value.length < 50 && value.match(/^[^+/*\\-].*[+/*\\-]/)) {
    var vars = value.match(/[a-z0-9]+/gi);
    vars.forEach((variable) => {
      if (row && isset(row[variable])) {
        value = value.replaceAll(variable, row[variable]);
      }
    });
    try {
      value = eval(value);
      if (!isNumeric(value)) value = 0;
    } catch (e) {
      console.error(e);
    }
  } else if (!(settings.values && inArray(value, settings.values))) {
    if (inArray(value, ['random'])) value = null;
    else if (calc) value = '';
  }

  if (
    settings.number &&
    isset(value) &&
    !isNumber(value) &&
    settings.maxlength != 1
  ) {
    if (isString(value)) value = value.replace(',', '.');
    if (isNaN(value)) value = 0;
    else if (settings.decimal) value = parseFloat(value);
    else value = parseInt(value);
  }
  if (param.currency) {
    if (!value || str_contains(value, 'currency')) value = store.currency;
    if (value == 'EUR') value = '€';
    else if (value == 'GBP)') value = '£';
    else if (value == 'USD') value = '$';
  }

  return value;
}
